<template>
    <v-layout class="page" fill-height>
        <div class="box-container">
            <div class="conBox">
                <table class="tbl tblInp">
                    <caption>사용자 계정 상세</caption>
                    <colgroup>
                        <col style="width:25%;">
                        <col />
                        <col style="width:25%;">
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th class="text-center">{{ $t('page.user.name') }} ({{ $t('page.user.id') }})</th>
                            <td>{{ user.userNm }} ({{ user.loginId }})</td>
                            <th class="text-center">{{ $t('page.user.auth') }}</th>
                            <td>{{ user.role === "A" ? $t('page.myPage.userType.adm') : $t('page.myPage.userType.usr') }}</td>
                        </tr>
                        <tr>
                            <th class="text-center">{{ $t('page.user.crtDt') }}</th>
                            <td>{{ user.crtDtStr }}</td>
                            <th class="text-center">{{ $t('page.user.status') }}</th>
                            <td style="display: flex; justify-content: space-between; align-items: center; border: none">
                                <span :class="user&&getStateChipClass(user.useYn)">{{user.useYn === 'Y'? $t('page.myPage.userState.enable'):$t('page.myPage.userState.disable') }}</span>
                                <button class="btn solid small" @click="openStatePopup = true">{{ $t('page.user.detail.button.changeStts') }}</button>
                            </td>
                        </tr>
                        <tr>
                            <th class="text-center">
                                <span style="display: block; margin-bottom: 10px;">{{ $t('page.user.remark') }}</span>
                                <button v-if="!isChangingNote" class="btn default small" @click="isChangingNote = true">{{$t('common.button.mod')}}</button>
                                <button v-else class="btn solid small" @click="changeUserNote()">{{$t('common.button.save')}}</button>
                            </th>
                            <td colspan="3">
                                <v-textarea flat solo density="compact" hide-details="auto" :disabled="!isChangingNote" v-model="user.note"></v-textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="btnW mt40">
                    <button class="btn default" @click="$router.push({name:'userManage'})" v-ripple>{{$t('common.button.list')}}</button>
                    <button class="btn solid" @click="openPopup()" v-ripple>{{ $t('page.user.detail.button.initPw') }}</button>
                </div>
            </div>
        </div>

        <v-dialog v-model="popupVisible">
            <ChangePwPopup
                :onClick="() => { popupVisible = false }"
                :userNo="userNo"
                :initialValues="initialValues"
                :isAdmin="true"
                @closePopup="closePopup"
            />
        </v-dialog>
        <v-dialog v-model="openStatePopup" width="320">
            <DefaultPopup
                :popupText="popupText"
                :secondTxt="popupButton"
                :firstBtn="true"
                :onClick="() => {  changeStateProgress();  }"
                @closePopup="openStatePopup = false"
            />
        </v-dialog>
    </v-layout>
</template>

<script>
import API_USER from '../../../API/user/detail';
import { rules } from '../../../mixin/rules'
import API_CHANGE_STATE from '@/API/user/changeState';
import API_CHANGE_NOTE from '@/API/user/changeNote';
export default {
    mixins: [rules],
    components: {
        ChangePwPopup: () => import('../../../popup/changePwPopup.vue'),
    },
    inject: ["setRightSideHidden", "getRightSideHidden"],
    props: {},
    data() {
        return {
            isGettingItems: false,
            user: '',
            popupVisible: false,
            userNo: this.$route.params.no,
            initialValues: {
                newPw: '',
                chkPw: ''
            },
            userMaxCnt: process.env.VUE_APP_USER_MAX_CNT,
            activeUserCnt: 0,
            openStatePopup: false,
            isChangingNote: false,
            originalNote: ''
        };
    },
    watch: {
    },
    created() { },
    async mounted() {
        this.setRightSideHidden(false);
        await this.initData(this.$route.params.no)
    },
    destroyed() { 
        localStorage.removeItem('activeUserCnt')
    },
    methods: {
        async initData(no) {
            if (this.isGettingItems) {
                return;
            }
            this.isGettingItems = true;
            let res = await API_USER.request(no);
            if (res.isSuccess) {
                this.user = res.user;
                this.originalNote = res.user.note;
                this.activeUserCnt = localStorage.getItem('activeUserCnt');
            }else{
                this.showPopup(res.errorMsg, res.status);
            }
            this.isGettingItems = false;
        },
        openPopup() {
            this.popupVisible = true;

        },
        closePopup() {
            this.popupVisible = false;
        },
        changeStateProgress(){
            const nowState = this.user.isActive;
            if (nowState === false) {
                if (parseInt(this.userMaxCnt) < parseInt(this.activeUserCnt)) {
                    this.showPopup(this.$t('page.user.list.msg.changeState'));
                    this.openStatePopup = false;
                }else{
                    this.getNewState();
                }
            } else {
                this.getNewState();
            }
        },
        async getNewState(){
            let res = await API_CHANGE_STATE.request(this.userNo);
            if (res.isSuccess) {
                this.openStatePopup = false;
                this.initData(this.userNo);
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
        },
        async changeUserNote(){
            if(this.originalNote === this.user.note) {
                this.showPopup(this.$t('page.myPage.msg.same'))
                this.isChangingNote = false;
                return
            }
            let res = await API_CHANGE_NOTE.request(this.userNo, this.user.note);
            if (res.isSuccess) {
                this.showPopup(this.$t('page.myPage.msg.change'))
                this.isChangingNote = false;
                this.initData(this.userNo);
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
        },
        getStateChipClass(useYn){
            if(useYn === 'Y') return 'active'
            else return 'nonActive'
        },
    },
    computed: {
        popupButton(){
            return this.$t('common.button.ok')
        },
        popupText(){
            return this.$t('page.user.detail.popup.changeStts')
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
::v-deep .tbl{
    overflow: hidden;
}

button{
  transition: box-shadow 0.5s, opacity 0.5s;
}
button:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.75;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{padding:0;}
::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot{margin-right: 0;}
::v-deep .v-textarea.v-text-field--enclosed textarea{margin-top: 0 !important; padding: 10px !important; font-size: 1.4rem}
.tbl td span.active{font-weight: bold;}
.tbl td span.nonActive{color: #666;}

.v-chip.nonActive{background-color:#F5F5F5; color: #666;}
.tbl tbody tr:hover{background-color: #fff;}

.tbl td.nonActive{background-color:#F5F5F5;}
</style>